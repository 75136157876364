<template>
    <div class="mobile-page-layout font-IRANSans">
        <div class="news-title-row">
            <div class="news-title-back-btn">
                <div class="mobile-page-header-icon pointer back-icon" v-on:click="back()">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>
            </div>
            <div class="news-title-content">
                <h2 class="market-news-content-title" v-if="content" v-html="content.title"></h2>
                <div class="market-news-content-date" v-if="content" v-html="content.jalali_created_at"></div>
            </div>
        </div>
        <img class="market-news-content-image" v-if="content" :src="content.image">
        <div class="tgcss-px-3 tgcss-pb-2"><div class="tgcss-bg-gray-100 tgcss-leading-7 tgcss-text-xs tgcss-font-semibold tgcss-rounded-md tgcss-p-3" v-html="content.summary"></div></div>

        <slot v-if="content.metadata !== null && content.metadata['broker_rating_1'] !== undefined && content.metadata.broker_rating_1 !== undefined">
            <div v-if="content && content.metadata && content.metadata.broker_rating_1 && content.metadata.broker_rating_1.value" class="tgcss-p-3 tgcss-px-4 tgcss-flex tgcss-items-end" >
                <div class="tgcss-w-24 tgcss-min-w-24 tgcss-max-w-24 tgcss-pb-3">
                    <div class="tgcss-text-xs tgcss-font-semibold">رتبه کلی</div>
                    <div class="tgcss-text-5xl tgcss-font-extrabold tgcss-py-2">{{ content.metadata.broker_rating_1.value }}</div>
                    <div class="">                    
                        <div v-if="rating > 0" class="rating  tgcss-flex tgcss-items-center">
                            <!-- ستاره‌های پر -->
                            <svg v-for="n in fullStars"  :key="'full-' + n" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <linearGradient id="grad__star__101">
                                        <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="100%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                                    </linearGradient>
                                </g>
                                <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__101)"></path>
                            </svg>
                            <!-- ستاره نیمه‌پر -->
                            <svg v-if="hasHalfStar" style="transform: scaleX(-1);" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <linearGradient id="grad__star__64">
                                        <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="50%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="50%" stop-color="#fefefe" stop-opacity="1"></stop>
                                        <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                                    </linearGradient>
                                </g>
                                <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__64)"></path>
                            </svg>
                            <!-- ستاره‌های خالی -->
                            <svg v-for="n in emptyStars" :key="'empty-' + n" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <linearGradient>
                                        <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="100%" stop-color="#f8931b" stop-opacity="1"></stop>
                                        <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                                    </linearGradient>
                                </g>
                                <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__100)"></path>
                            </svg>
                        </div>
                    </div>

                </div>
                <div class="tgcss-grow tgcss-flex tgcss-justify-start tgcss-overflow-x-auto tgcss-pb-2">
                    <div v-for="n in 9" :key="n" class="tgcss-flex tgcss-flex-col tgcss-justify-center tgcss-items-center tgcss-ml-3">
                        <div  class="tgcss-flex">
                            <div class="tgcss-font-semibold" style="writing-mode: vertical-rl; text-orientation: mixed; width: 15px; line-height: 1; font-size: 10px;">
                                {{ content.metadata['broker_rating_' + (n + 1)].title }}
                            </div>
                            <div class="tgcss-h-32 tgcss-w-2 tgcss-rounded-md tgcss-bg-gray-200 tgcss-relative">
                                <div class="tgcss-w-2 tgcss-bg-gray-800 tgcss-rounded-md tgcss-bottom-0 tgcss-absolute" 
                                    :style="{ height: (content.metadata['broker_rating_' + (n + 1)].value / 5) * 100 + '%' }">
                                </div>  
                            </div>
                        </div>
                        <div class="tgcss-font-semibold tgcss-pt-1">{{ content.metadata['broker_rating_' + (n + 1)].value }}</div>
                    </div>
                </div>
            </div>
        </slot>

        <slot v-if="content.metadata !== null">
            <div v-if="content && content.metadata && content.metadata['tab-active'] !== undefined && content.metadata['tab-active'].value == 'فعال'" class="tgcss-px-4">
                <div class="tgcss-flex tgcss-items-center">
                    <div @click="tabActive = 1" class="tgcss-bg-gray-200 tgcss-text-gray-900 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap  tgcss-rounded-full tgcss-ml-1.5 tgcss-pt-1 tgcss-pb-1.5 tgcss-px-3 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer"
                    :class="{ '!tgcss-bg-gray-900 !tgcss-text-white !hover:tgcss-bg-gray-900 !hover:tgcss-text-white': tabActive == 1}"
                    >
                        اطلاعات عمومی
                    </div>
                    
                    <div @click="tabActive = 2" class="tgcss-bg-gray-200 tgcss-text-gray-900 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap  tgcss-rounded-full tgcss-ml-1.5 tgcss-pt-1 tgcss-pb-1.5 tgcss-px-3 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer"
                    :class="{ '!tgcss-bg-gray-900 !tgcss-text-white hover:!tgcss-bg-gray-900 hover:!tgcss-text-white': tabActive == 2}"
                    >
                        ویژگی های تجارت
                    </div>
                    
                    <div @click="tabActive = 3" class="tgcss-bg-gray-200 tgcss-text-gray-900 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap  tgcss-rounded-full tgcss-ml-1.5 tgcss-pt-1 tgcss-pb-1.5 tgcss-px-3 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer"
                    :class="{ '!tgcss-bg-gray-900 !tgcss-text-white hover:!tgcss-bg-gray-900 hover:!tgcss-text-white': tabActive == 3}"
                    >     
                        سایر جزئیات  
                    </div>
                </div>

                <div class="tgcss-divide-y tgcss-divide-gray-200 tgcss-border tgcss-rounded-lg tgcss-mt-3">

                    <div v-for="(item, key) in content.metadata" :key="key" v-if="key.includes('tab1') && tabActive == 1" class="tgcss-flex">
                        <div class="tgcss-min-w-32 tgcss-w-32 tgcss-max-w-32 tgcss-p-2 tgcss-text-xs tgcss-font-semibold tgcss-leading-6">{{ item.title }} </div>
                        <div class="tgcss-grow tgcss-p-2 tgcss-text-xs tgcss-font-medium tgcss-leading-6">{{ item.value }}</div>
                    </div>
                    
                    <div v-for="(item, key) in content.metadata" :key="key" v-if="key.includes('tab2') && tabActive == 2" class="tgcss-flex">
                        <div class="tgcss-min-w-32 tgcss-w-32 tgcss-max-w-32 tgcss-p-2 tgcss-text-xs tgcss-font-semibold tgcss-leading-6">{{ item.title }} </div>
                        <div class="tgcss-grow tgcss-p-2 tgcss-text-xs tgcss-font-medium tgcss-leading-6">{{ item.value }}</div>
                    </div>
                    
                    <div v-for="(item, key) in content.metadata" :key="key" v-if="key.includes('tab3') && tabActive == 3" class="tgcss-flex">
                        <div class="tgcss-min-w-32 tgcss-w-32 tgcss-max-w-32 tgcss-p-2 tgcss-text-xs tgcss-font-semibold tgcss-leading-6">{{ item.title }} </div>
                        <div class="tgcss-grow tgcss-p-2 tgcss-text-xs tgcss-font-medium tgcss-leading-6">{{ item.value }}</div>
                    </div>
                    
                </div>
            </div>
        </slot>

        <slot v-if="content.metadata !== null && content.metadata['brokers_best_field_01'] !== undefined">
            <div v-for="(item, index) in filteredMetadata" :key="index" class="tgcss-px-3">
                <div class="tgcss-border tgcss-rounded-lg tgcss-p-3 tgcss-mb-3">
                    <div class="tgcss-text-center tgcss-font-semibold tgcss-text-sm tgcss-bg-gray-700 tgcss-text-white tgcss-rounded-lg tgcss-py-2 tgcss-mb-2">{{ item.field }}</div>
                    <div class="tgcss-text-center tgcss-flex tgcss-items-center tgcss-justify-center tgcss-mb-2">
                        <img class="tgcss-rounded-full tgcss-max-w-12 tgcss-ml-2" :src='getTitle("logo", item.title)'>
                        <div class="tgcss-font-semibold tgcss-text-lg">{{ getTitle("title", item.title)  }}</div>
                    </div>
                    <div class="tgcss-text-center tgcss-mb-2 tgcss-flex tgcss-items-center tgcss-justify-center">
                        <div class="tgcss-font-semibold tgcss-text-sm">{{ item.rate }}</div>
                        <Stars v-if="item.rate" :star="item.rate" class="tgcss-mr-1"></Stars>
                    </div>
                    <a :href="item.link" class="!tgcss-w-full tgcss-block !tgcss-no-underline tgcss-py-3 tgcss-mb-1  !tgcss-bg-[#f8931b] !tgcss-text-white tgcss-font-semibold tgcss-text-xs tgcss-rounded-lg tgcss-text-center">مشاهده بروکر</a>
                    <div class="tgcss-text-center tgcss-mb-3 tgcss-text-xs tgcss-opacity-70">برای مراجعه به سایت بروکر از لینک فوق استفاده نمایید</div>

                    <div class="tgcss-grid tgcss-grid-cols-1 xs:tgcss-grid-cols-3 tgcss-gap-3">
                        <div class="tgcss-border tgcss-rounded-lg tgcss-p-4 tgcss-mb-2 tgcss-text-center">
                            <div class="tgcss-font-medium tgcss-text-xss tgcss-opacity-50 tgcss-mb-1 tgcss-text-nowrap">حداقل‌سپرده</div>
                            <div class="tgcss-font-semibold tgcss-text-base">{{ item.deposit }}</div>
                        </div>
                        
                        <div class="tgcss-border tgcss-rounded-lg tgcss-p-4 tgcss-mb-2 tgcss-text-center tgcss-text-nowrap">
                            <div class="tgcss-font-medium tgcss-text-xss tgcss-opacity-50 tgcss-mb-1">بازارها</div>
                            <div class="tgcss-font-semibold tgcss-text-base">{{ item.instruments }}</div>
                        </div>
                        
                        <div class="tgcss-border tgcss-rounded-lg tgcss-p-4 tgcss-mb-2 tgcss-text-center tgcss-text-nowrap">
                            <div class="tgcss-font-medium tgcss-text-xss tgcss-opacity-50 tgcss-mb-1">کارمزد</div>
                            <div class="tgcss-font-semibold tgcss-text-base">{{ item.fees }}</div>
                        </div>
                    </div>

                    <a v-if="item.review !== null" :href="'/news/content/'+getNumberFromUrl(item.review)" class="!tgcss-no-underline !tgcss-text-gray-600 tgcss-text-center tgcss-font-semibold tgcss-opacity-80 tgcss-flex tgcss-items-center tgcss-justify-center">بررسی <div class="tgcss-relative tgcss-top-0.5 tgcss-mr-1">{{ getTitle("title", item.title)  }}</div> </a>

                </div>
            </div>
        </slot>

        <div class="market-news-content-body" v-if="content" v-html="formattedBody"></div>

        <slot v-if="content.metadata !== null">
            <div v-if="content && content.metadata && content.metadata['faq']  !== undefined && content.metadata['faq'].value.length > 10" class="tgcss-px-4">
                <div class="tgju-post-faq" v-for="(item, index) in faqList" :key="index">
                    <h3 @click="toggle(index)">{{ item.title }}</h3>
                    <span v-if="activeIndex == index" class="faq-content tgcss-block">
                        {{ item.content }}
                    </span>
                </div>
            </div>
        </slot>

    </div> 
</template>

<script>
import Stars from '@/components/Inc/stars.vue'

export default {
    name: 'ApplicationNewsContent',
    components: {
        Stars
    },
    props: ['content'],
    data: function () {
        return {
            faqList: [],
            metadata: {},
            activeIndex: null,
            tabActive:1,
            rating: 0
        }
    },
    mounted() {
        if(this.content.metadata.broker_rating_1 !== undefined ){
            this.rating =  this.content.metadata.broker_rating_1.value || 0
        }
    },
    watch: {
        rawFaq: {
        immediate: true,
        handler(value) {
            this.parseFaq(value);
        }
        }
    },
    computed: {
        filteredMetadata() {
            if (!this.content.metadata) return [];
            
            let result = [];
            for (let i = 1; i <= 20; i++) {
                let key = i.toString().padStart(2, "0"); // تبدیل 1 به 01
                let field = this.content.metadata[`brokers_best_field_${key}`]?.value || null;
                let rate = this.content.metadata[`brokers_best_rate_${key}`]?.value || null;
                let title = this.content.metadata[`brokers_best_title_${key}`]?.value || null;
                let link = this.content.metadata[`brokers_best_link_${key}`]?.value || null;
                let deposit = this.content.metadata[`brokers_best_deposit_${key}`]?.value || null;
                let fees = this.content.metadata[`brokers_best_fees_${key}`]?.value || null;
                let instruments = this.content.metadata[`brokers_best_instruments_${key}`]?.value || null;
                let review = this.content.metadata[`brokers_best_review_${key}`]?.value || null;
                let logo = this.content.metadata[`brokers_logo_field_${key}`]?.value || null;

                if (field) { // فقط مواردی که مقدار دارند اضافه شوند
                result.push({ field, rate, title, link, deposit, fees, instruments, review, logo });
                }
            }
            return result;
        },
        rawFaq() {
            return this.content?.metadata?.faq?.value || "";
        },
        formattedBody() {
            return this.content.body
            // .replace(/\r\n/g, '<br>') // تبدیل \r\n به <br>
            // .replace(/(<\/?(?:span|img|b|strong|br|h1|h2|h3|h4|h5|h6|ul|li|ol|table|thead|tbody|tr|th|td|div)[^>]*>)|<[^>]+>/ig, '$1'); // پاکسازی تگ‌های غیرمجاز
            
        },
        fullStars() {
        return Math.floor(this.rating);
        },
        hasHalfStar() {
        return this.rating % 1 >= 0.5;
        },
        emptyStars() {
        return 5 - this.fullStars - (this.hasHalfStar ? 1 : 0);
        }
    },
    methods: {
        getNumberFromUrl(url) {
            if(url !== null){
                let match = url.match(/news\/(\d+)\//);
                return match ? match[1] : null;
            }
            
        },
        getTitle(tag, text) {
            let regex = new RegExp(`<${tag}>\\s*(.*?)\\s*</${tag}>`, "s");
            let match = text.match(regex);
            return match ? match[1] : null;
        },
        isTabActiveDefined() {
            return this.content && this.content.metadata && this.content.metadata['tab-active'] !== undefined;
        },
        parseFaq(rawText) {
            const cleanText = rawText.replace(/<\/?[^>]+(>|$)/g, ""); // حذف تگ‌های HTML
            const lines = cleanText.split("\n").filter(line => line.trim() !== ""); // حذف خطوط خالی
            this.faqList = [];

            for (let i = 0; i < lines.length; i += 2) {
                if (lines[i + 1]) {
                this.faqList.push({ title: lines[i].trim(), content: lines[i + 1].trim() });
                }
            }
        },
        toggle(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    }
}
</script>
