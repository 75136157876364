import { render, staticRenderFns } from "./newsRow.vue?vue&type=template&id=186dbc42&"
import script from "./newsRow.vue?vue&type=script&lang=js&"
export * from "./newsRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports